import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { del, get, post, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import AddForm from './Add';
import * as Utils from "../../Utils";
import moment from 'moment-timezone';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import * as XLSX from 'xlsx';

export default function Genre() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [file, setFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'S.N',
                selector: (row, index) => (perPage * (currentPage - 1)) + index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Image',
                cell: (row) => <>
                    <img title={'image'} src={row?.image} style={{ width: 50, height: 'auto', marginTop: 5, marginBottom: 5 }} />
                </>,
                maxWidth: '110px'
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row)}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row)}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [data, perPage, currentPage]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("fuser", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleUpdateStatus = (row) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Status" });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setIsAdd(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                userId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("fuser", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("fuser", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const handleValidSubmit = async (e, v) => {
        setLoading(true);
        const response = await fetch(file);
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(worksheet);
        const uniqueArray = sheetData.filter((value, index, self) =>
            index === self.findIndex((obj) => obj.name === value.name)
        );
        console.log("values", uniqueArray);
        post("fuser/upload", { data: uniqueArray, token: user?.token })
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setUploadModal(false);
                    setFile(null);
                    setUploadModal(false);
                    getData();
                } else {
                    toast.error("" + res?.error);
                }
            })
            .catch(err => {
                setLoading(false);
                console.error("error while uploading users", err);
                toast.error("Something Went Wrong!");
            })
    }

    // console.log("current page", currentPage);

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <Modal isOpen={uploadModal} centered={true} style={{ maxWidth: 540 }}>
                <ModalBody>
                    <h5>Upload Users via Excel file</h5>
                    <div className="me-3 position-relative">
                        <AvForm
                            className="form-horizontal mt-4"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="excel"
                                            label={"Xlsx *"}
                                            className="form-control"
                                            type="file"
                                            required
                                            accept=".xlsx"
                                            onChange={(e) => setFile(URL.createObjectURL(e?.target.files[0]))}
                                        />
                                    </div>
                                </Col>
                                <div className="text-center mt-4 d-flex justify-content-around">
                                    <Button type="reset" style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                        onClick={() => { setCurrentData(null); setUploadModal(false) }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" style={{ backgroundColor: Utils.themeColor, border: 'none' }}>
                                        Upload
                                    </Button>
                                </div>
                            </Row>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
            {!isAdd ?
                <div className="main main-app p-3 p-lg-4">
                    <Card>
                        <Row className='mb-4'>
                            <Col md={10}>
                                <CardBody>
                                    <CardTitle><b>Temporary Users</b></CardTitle>
                                    <CardSubtitle>Temporary Users you have added before all the users can <code>post</code> or <code>like real posts</code> automatically.</CardSubtitle>
                                </CardBody>
                            </Col>
                            {checkPermission("plans", "write") ?
                                <>
                                    <Col md={1}>
                                        <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                            <Button type="button" onClick={() => { setCurrentData(null); setUploadModal(true) }} className="btn-sign" style={{ float: 'right', width: "100%" }}>Upload</Button>
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                            <Button type="button" onClick={() => { setCurrentData(null); setIsAdd(true) }} className="btn-sign" style={{ float: 'right', width: "100%" }}>Add</Button>
                                        </div>
                                    </Col>
                                </>
                                : null}
                        </Row>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            onChangePage={(p) => setCurrentPage(p)}
                            onChangeRowsPerPage={(p) => setPerPage(p)}
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                            conditionalRowStyles={[{
                                when: row => row?.style,
                                style: row => ({ width: row?.style?.width }),
                            },
                            ]}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                    },
                                }
                            }}
                        />
                    </Card>
                    <Footer />
                </div>
                :
                <AddForm
                    user={user}
                    setIsAdd={setIsAdd}
                    loading={loading}
                    setLoading={setLoading}
                    getData={getData}
                    currentData={currentData}
                />
            }
        </React.Fragment>
    )
}
