import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalBody, Label } from 'reactstrap';
import { del, get, post, put, upload, uploadS3 } from '../../helper/api_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../../Utils";


function AddForm(props) {
    const { setIsAdd, user, currentData, loading, setLoading, getData, cats } = props;
    const [cropperModal, setCropperModal] = useState(false);
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState("image");
    const [image, setImage] = useState(null);
    const imageInput = useRef();
    const cropper = useRef();
    const [progress, setProgress] = useState(0);

    useState(() => {
        if (currentData?.image) {
            setFile(currentData?.image);
            setImage(currentData?.image);
        }
    }, [currentData])

    const handleImgChange = (file) => {
        setFile(URL.createObjectURL(file));
        setCropperModal(true);
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                token: user?.token
            }
            if (currentData?._id) {
                body = { ...body, subCatId: currentData?._id }
                put("sub-category", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("sub-category", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    // console.log("updateing plan", currentData);

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <input
                    name="image"
                    label="image"
                    className="d-none"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImgChange(e.target.files[0])}
                    ref={imageInput}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={10}>
                                <CardTitle className='mb-4'><b>{currentData?._id ? 'Update sub category' : 'Add New sub category'}</b></CardTitle>
                            </Col>
                            <Col>
                                <Button type="button" onClick={() => setIsAdd(false)} className="btn-sign" style={{ float: 'right' }}>Close</Button>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="catId"
                                            label="Category *"
                                            value={currentData?.catId}
                                            required
                                            type="select"
                                        >
                                            <option value="">Select Category</option>
                                            {cats?.map((item) => (
                                                <option value={item?._id}>{item?.title}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            placeholder="Enter sub category title"
                                            value={currentData?.title}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} />
                                <Col md={2}>
                                    <Button type="reset" onClick={() => setIsAdd(false)} className="btn-sign w-100">Cancel</Button>
                                </Col>
                                <Col md={2}>
                                    <Button type="submit" className="btn-sign w-100">{currentData?._id ? 'Update' : 'Add'}</Button>
                                </Col>
                                <Col md={4} />
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card >
            </div >
        </React.Fragment >
    )
}

export default AddForm;