import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalBody, Label } from 'reactstrap';
import { del, get, post, put, upload, uploadS3 } from '../../helper/api_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../../Utils";
import Multiselect from 'multiselect-react-dropdown';

function AddForm(props) {
    const { setIsAdd, user, currentData, loading, setLoading, getData, cats } = props;
    const [selectedCat, setSelectedCat] = useState(undefined);
    const [subCats, setSubCats] = useState([]);
    const [selectedSub, setSelectedSub] = useState([]);
    const [descs, setDescs] = useState([""]);

    useEffect(() => {
        if (currentData?.catId)
            setSelectedCat(currentData?.catId);
    }, [currentData]);

    useEffect(() => {
        if (currentData?.subCatId?.length && subCats?.length) {
            let tempSubs = [];
            currentData?.subCatId?.forEach((item) => {
                let found = subCats.find(x => x?._id == item)
                if (found)
                    tempSubs.push(found);
            });
            setSelectedSub(tempSubs);
        }
    }, [currentData, subCats]);

    useEffect(() => {
        if (selectedCat) {
            get("sub-category/drop?catId=" + selectedCat, { token: user?.token })
                .then(res => {
                    if (res?.statusCode == 200) {
                        setSubCats(res?.data)
                    }
                })
        }
    }, [selectedCat])

    const handleValidSubmit = async (e, v) => {
        let tempSubs = [];
        selectedSub?.forEach((item) => {
            tempSubs.push(item?._id);
        });
        if (tempSubs?.length < 1) {
            toast.error("Select atleast one sub category!");
            return;
        }
        if (tempSubs?.length > 3) {
            toast.error("Select only three sub category!");
            return;
        }
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                subCatId: tempSubs,
                token: user?.token
            }
            if (currentData?._id) {
                body = { ...body, requireId: currentData?._id }
                put("requirement", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                descs?.forEach((item, index) => {
                    post("requirement", { ...body, desc: item })
                        .then(res => {
                            if (descs?.length - 1 == index)
                                setLoading(false);
                            if (res?.statusCode == 200) {
                                if (descs?.length - 1 == index) {
                                    toast.success(res?.message);
                                    setIsAdd(false);
                                    getData();
                                }
                            } else {
                                setLoading(false);
                                toast.error(res?.error);
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("Error while adding city", err);
                            toast.error("Something Went Wrong!");
                        })
                })
            }
        }
    }

    const handleMore = () => {
        let temp = [...descs];
        temp.push("");
        setDescs(temp);
    }

    const handleRemove = (i) => {
        let temp = [...descs];
        temp.splice(i, 1);
        setDescs(temp);
    }

    const handleInput = (val, i) => {
        let temp = [...descs];
        temp[i] = val;
        setDescs(temp);
    }

    // console.log("found both array", selectedSub);

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={10}>
                                <CardTitle className='mb-4'><b>{currentData?._id ? 'Update requirement' : 'Add New requirement'}</b></CardTitle>
                            </Col>
                            <Col>
                                <Button type="button" onClick={() => setIsAdd(false)} className="btn-sign" style={{ float: 'right' }}>Close</Button>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="catId"
                                            label="Category *"
                                            value={currentData?.catId}
                                            required
                                            type="select"
                                            onChange={(e) => setSelectedCat(e.target.value)}
                                        >
                                            <option value="">Select Category</option>
                                            {cats?.map((item) => (
                                                <option value={item?._id}>{item?.title}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <Label>Sub Categories *</Label>
                                        <Multiselect
                                            options={subCats}
                                            onSelect={(selectedList, selectedItem) => { if (selectedList?.length < 4) setSelectedSub(selectedList); else toast.error("You can select only 3 sub categories Max!") }}
                                            onRemove={(selectedList, removedItem) => setSelectedSub(selectedList)}
                                            displayValue="title"
                                            placeholder={"Select Sub Categories"}
                                            showCheckbox
                                            name="Sub Categories"
                                            selectedValues={selectedSub}
                                        />
                                    </div>
                                </Col>
                                {currentData?._id ?
                                    <Col md={12}>
                                        <div className='mb-4 position-relative'>
                                            <AvField
                                                name={"desc"}
                                                label={`Requirement *`}
                                                placeholder="Enter your requirement"
                                                value={currentData?.desc}
                                                required
                                                type="textarea"
                                                rows={3}
                                            />
                                        </div>
                                    </Col>
                                    :
                                    <>
                                        {descs?.map((item, index) => (
                                            <Col md={12} key={index}>
                                                <div className='mb-4 position-relative'>
                                                    <AvField
                                                        name={"desc" + index}
                                                        label={`Requirement ${index + 1} *`}
                                                        placeholder="Enter your requirement"
                                                        value={item}
                                                        required
                                                        type="textarea"
                                                        rows={3}
                                                        onChange={(e) => handleInput(e?.target?.value, index)}
                                                    />
                                                </div>
                                                {descs?.length > 1 ?
                                                    <Button type="button" className="btn-sign" style={{ float: 'right' }}
                                                        onClick={() => handleRemove(index)}
                                                    ><i className='ri-delete-bin-6-line' /></Button>
                                                    : null}
                                                {descs?.length - 1 == index ?
                                                    <Button type="button" className="btn-sign w-10 mb-4" style={{ float: 'right', marginRight: 10 }}
                                                        onClick={() => handleMore()}
                                                    >+ Add More</Button>
                                                    : null}
                                            </Col>
                                        ))}
                                    </>
                                }
                            </Row>
                            <Row>
                                <Col md={4} />
                                <Col md={2}>
                                    <Button type="reset" onClick={() => setIsAdd(false)} className="btn-sign w-100">Cancel</Button>
                                </Col>
                                <Col md={2}>
                                    <Button type="submit" className="btn-sign w-100">{currentData?._id ? 'Update' : 'Add'}</Button>
                                </Col>
                                <Col md={4} />
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card >
            </div >
        </React.Fragment >
    )
}

export default AddForm;