import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../store/userSlice";
import { get } from "../helper/api_helper";
import { toast } from "react-toastify";

export default function Sidebar() {
    const scrollBarRef = useRef();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user])

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("user/detail?userId=" + user?._id, { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        if (user != res?.data) {
                            dispatch(setUser(res?.data));
                            localStorage.setItem("user", JSON.stringify(res?.data));
                        }
                    } else {
                        toast.error(res?.error);
                    }
                })
                .catch(err => {
                    console.log('error while getting user', err);
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    const populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" style={{ userSelect: 'none' }} key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link"><i className={m.icon} style={{ fontSize: 15 }}></i> <span style={{ userSelect: 'none' }}>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span style={{ userSelect: 'none' }}>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    // Toggle menu group
    const toggleMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');
        // props.onUpdateSize();
        scrollBarRef.current?.updateScroll()
    }

    // Toggle submenu while closing siblings' submenu
    const toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        scrollBarRef.current?.updateScroll()
    }

    const checkPermission = (to) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            if (permission?.length) {
                let find = permission.find(x => Object.keys(x).includes(to))
                if (find)
                    return find[to]?.read
            }
        } else {
            return false;

        }
    }

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <div className="d-flex align-items-center">
                    <Link to="/" className="sidebar-logo">
                        <img src={require('../assets/images/logo.png')} style={{ height: 50, width: "auto" }} />
                    </Link>
                    {/* <p style={{ fontSize: 12, fontWeight: "bold", marginBottom: 0 }}>{Utils.projectName}</p> */}
                </div>
            </div>
            <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
                {/* <SidebarMenu onUpdateSize={() => scrollBarRef.current?.updateScroll()} user={user} /> */}
                <React.Fragment>
                    <div className="nav-group show">
                        {user?.role == "Admin" ?
                            <ul className="nav nav-sidebar py-0">
                                <li className="nav-item">
                                    <NavLink to="/" className="nav-link"><i className={"ri-home-3-line"}></i> <span style={{ userSelect: 'none' }}>Dashboard</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/users" className="nav-link"><i className={"ri-group-2-line"}></i> <span style={{ userSelect: 'none' }}>Users</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/banner" className="nav-link"><i className={"ri-image-line"}></i> <span style={{ userSelect: 'none' }}>Banner</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/categories" className="nav-link"><i className={"ri-shape-line"}></i> <span style={{ userSelect: 'none' }}>Category</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/sub-categories" className="nav-link"><i className={"ri-git-merge-line"}></i> <span style={{ userSelect: 'none' }}>Sub Category</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/meta-data" className="nav-link"><i className={"ri-pie-chart-line"}></i> <span style={{ userSelect: 'none' }}>Meta-Data</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/posts" className="nav-link"><i className={"ri-image-edit-line"}></i> <span style={{ userSelect: 'none' }}>Posts</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/fake-users" className="nav-link"><i className={"ri-user-unfollow-line"}></i> <span style={{ userSelect: 'none' }}>Temp Users</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/fake-city" className="nav-link"><i className={"ri-building-line"}></i> <span style={{ userSelect: 'none' }}>Temp City</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/requirement" className="nav-link"><i className={"ri-article-line"}></i> <span style={{ userSelect: 'none' }}>Temp Requirement</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/ads" className="nav-link"><i className={"ri-image-2-line"}></i> <span style={{ userSelect: 'none' }}>Ads Manager</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/links" className="nav-link"><i className={"ri-external-link-line"}></i> <span style={{ userSelect: 'none' }}>Links</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/social" className="nav-link"><i className={"ri-apps-line"}></i> <span style={{ userSelect: 'none' }}>Social Links</span></NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to="/terms" className="nav-link"><i className={"ri-mail-forbid-line"}></i> <span style={{ userSelect: 'none' }}>Terms & Condition</span></NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to="/enquiry" className="nav-link"><i className={"ri-phone-line"}></i> <span style={{ userSelect: 'none' }}>Enquiry</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/sub-admin" className="nav-link"><i className={"ri-key-line"}></i> <span style={{ userSelect: 'none' }}>Sub Admin</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/setting" className="nav-link"><i className={"ri-settings-line"}></i> <span style={{ userSelect: 'none' }}>Setting</span></NavLink>
                                </li>
                            </ul>
                            : null}
                        {user?.role == "Sub" ?
                            <ul className="nav nav-sidebar py-0">
                                <li className="nav-item">
                                    <NavLink to="/" className="nav-link"><i className={"ri-home-3-line"}></i> <span style={{ userSelect: 'none' }}>Dashboard</span></NavLink>
                                </li>
                                {checkPermission("tempRequirement") ?
                                    <li className="nav-item">
                                        <NavLink to="/requirement" className="nav-link"><i className={"ri-article-line"}></i> <span style={{ userSelect: 'none' }}>Temp Requirement</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("paidUserRequests") ?
                                    <li className="nav-item">
                                        <NavLink to="/users" className="nav-link"><i className={"ri-group-2-line"}></i> <span style={{ userSelect: 'none' }}>Paid Users Requests</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("verifiedUsers") ?
                                    <li className="nav-item">
                                        <NavLink to="/paid-users" className="nav-link"><i className={"ri-group-line"}></i> <span style={{ userSelect: 'none' }}>Verified Users</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("taskAssignment") ?
                                    <li className="nav-item">
                                        <NavLink to="/task" className="nav-link"><i className={"ri-task-line"}></i> <span style={{ userSelect: 'none' }}>Task Assignment</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("taskSubmitted") ?
                                    <li className="nav-item">
                                        <NavLink to="/task-submit" className="nav-link"><i className={"ri-check-double-fill"}></i> <span style={{ userSelect: 'none' }}>Task Submitted</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("withdrawRequest") ?
                                    <li className="nav-item">
                                        <NavLink to="/withdrawal" className="nav-link"><i className={"ri-wallet-line"}></i> <span style={{ userSelect: 'none' }}>Withdarwal Request</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("planUpgradeRequest") ?
                                    <li className="nav-item">
                                        <NavLink to="/upgrade" className="nav-link"><svg style={{ width: 30, height: 30, marginLeft: -5, marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M6 7 18 7V9L6 9 6 7ZM12 11 6 17H18L12 11Z"></path></svg> <span style={{ userSelect: 'none' }}>Plan Upgrade Request</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("enquiry") ?
                                    <li className="nav-item">
                                        <NavLink to="/enquiry" className="nav-link"><i className={"ri-phone-line"}></i> <span style={{ userSelect: 'none' }}>Enquiry</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("banner") ?
                                    <li className="nav-item">
                                        <NavLink to="/banner" className="nav-link"><i className={"ri-image-line"}></i> <span style={{ userSelect: 'none' }}>Banner</span></NavLink>
                                    </li>
                                    : null}
                                {checkPermission("plans") ?
                                    <li className="nav-item">
                                        <NavLink to="/plans" className="nav-link"><i className={"ri-money-rupee-circle-line mx-1"}>₹</i> <span style={{ userSelect: 'none' }}>Plans</span></NavLink>
                                    </li>
                                    : null}
                                {/* <li className="nav-item">
                        <NavLink to="/bank" className="nav-link"><i className={"ri-bank-line"}></i> <span style={{ userSelect: 'none' }}>Bank Details</span></NavLink>
                    </li> */}
                                {checkPermission("termsAndConditions") ?
                                    <li className="nav-item">
                                        <NavLink to="/terms" className="nav-link"><i className={"ri-mail-forbid-line"}></i> <span style={{ userSelect: 'none' }}>Terms & Condition</span></NavLink>
                                    </li>
                                    : null}
                            </ul>
                            : null}
                    </div>
                </React.Fragment>
            </PerfectScrollbar>
            <div className="sidebar-footer d-none">
                <div className="sidebar-footer-top">
                    <div className="sidebar-footer-thumb">
                        <img src={require("../assets/images/icon.png")} alt="" />
                    </div>
                    <div className="sidebar-footer-body">
                        <h6><Link to="../pages/profile.html">{user?.name}</Link></h6>
                        <p>{user?.role}</p>
                    </div>
                    <Link onClick={toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                </div>
                <div className="sidebar-footer-menu">
                    <nav className="nav">
                        {/* <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link> */}
                        {/* <Link to=""><i className="ri-profile-line"></i> View Profile</Link> */}
                    </nav>
                    <hr />
                    <nav className="nav">
                        {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                        <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                        <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link> */}
                        <Link to="/logout"><i className="ri-logout-box-r-line"></i>Log Out</Link>
                    </nav>
                </div>
            </div>
        </div>
    )

}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});