import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalBody, Label } from 'reactstrap';
import { del, get, post, put, upload, uploadS3 } from '../../helper/api_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../../Utils";


function AddForm(props) {
    const { setIsAdd, user, currentData, loading, setLoading, getData } = props;
    const [cropperModal, setCropperModal] = useState(false);
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState("image");
    const [image, setImage] = useState(null);
    const imageInput = useRef();
    const cropper = useRef();
    const [progress, setProgress] = useState(0);

    useState(() => {
        if (currentData?.image) {
            setFile(currentData?.image);
            setImage(currentData?.image);
        }
    }, [currentData])

    const handleImgChange = (file) => {
        setFile(URL.createObjectURL(file));
        setCropperModal(true);
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                token: user?.token
            }
            if (file && file.substring(0, 4) == "blob") {
                setLoading(true);
                const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
                const obj = await urltoFile(fileData, new Date().getTime() + '.png', 'image/png');
                const temp = await resizeFile(obj);
                const imageRes = await uploadS3("user", temp, "webp", (p) => setProgress(p));
                body.image = imageRes?.data;
            }
            if (currentData?._id) {
                body = { ...body, userId: currentData?._id }
                put("fuser", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("fuser", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    // console.log("updateing plan", currentData);

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <input
                    name="image"
                    label="image"
                    className="d-none"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImgChange(e.target.files[0])}
                    ref={imageInput}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={10}>
                                <CardTitle className='mb-4'><b>{currentData?._id ? 'Update User' : 'Add New User'}</b></CardTitle>
                            </Col>
                            <Col>
                                <Button type="button" onClick={() => setIsAdd(false)} className="btn-sign" style={{ float: 'right' }}>Close</Button>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={6} className='mt-4'>
                                    {file ?
                                        <>
                                            {image ?
                                                <div style={{ width: 100, position: 'relative' }}>
                                                    <img
                                                        src={image}
                                                        alt=""
                                                        style={{ width: 100, height: 100 }}
                                                    // onClick={(e) => imageInput.current.click(e)}
                                                    />
                                                    <div style={{ position: 'absolute', height: 40, width: 40, right: -28, top: -28, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                        onClick={() => { setFile(null); setImage("") }}
                                                    >
                                                        <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                                                    </div>
                                                </div>
                                                :
                                                <Cropper
                                                    style={{ height: 100, width: 100 }}
                                                    aspectRatio={1 / 1}
                                                    preview=".img-preview"
                                                    guides={true}
                                                    src={file}
                                                    ref={cropper}
                                                />
                                            }
                                        </>
                                        :
                                        <div style={{
                                            position: 'relative',
                                            width: 100,
                                            height: 100,
                                            border: '1px dashed ' + Utils.themeColor,
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: Utils.themeColor,
                                            fontSize: 22
                                        }} onClick={(e) => imageInput.current.click(e)}>
                                            <span
                                                style={{
                                                    padding: 5,
                                                    paddingLeft: 15, paddingRight: 15,
                                                    borderRadius: 5
                                                }}
                                            >
                                                <i className='ri-image-line' />
                                            </span>
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                            >
                                                <i className="ri-camera-line" style={{ color: 'white', fontSize: 22 }} />
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="name"
                                            label="Name *"
                                            placeholder="Enter User name"
                                            value={currentData?.name}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} />
                                <Col md={2}>
                                    <Button type="reset" onClick={() => setIsAdd(false)} className="btn-sign w-100">Cancel</Button>
                                </Col>
                                <Col md={2}>
                                    <Button type="submit" className="btn-sign w-100">{currentData?._id ? 'Update' : 'Add'}</Button>
                                </Col>
                                <Col md={4} />
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card >
            </div >
        </React.Fragment >
    )
}

export default AddForm;