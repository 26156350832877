import React, { useEffect, useState } from 'react';
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Label, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { del, get, post, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import AddForm from './Add';
import * as Utils from "../../Utils";
import moment from 'moment-timezone';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Loader from '../../components/Loader';

export default function MetaData() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [isLangAdd, setIsLangAdd] = useState(false);
    const [isCurrenAdd, setIsCurrenAdd] = useState(false);
    const [isScreenAdd, setIsScreenAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [charges, setCharges] = useState([]);
    const [chargeColumns, setChargeColumns] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [langColumns, setLangColumns] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currenColumns, setCurrenColumns] = useState([]);
    const [screens, setScreens] = useState([]);
    const [screenCoulmns, setScreenColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setChargeColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Title',
                selector: row => row.title,
                cell: row => <span style={{ width: "100%" }} title={row?.title}>{row?.title}</span>,
                sortable: true,
            },
            {
                name: 'Freelancers',
                selector: row => row?.freelancers,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                cell: row => <span title={moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}>{moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>,
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row, "Charge")}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row, "Charge")}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row, "Charge")}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [charges]);

    useEffect(() => {
        setCurrenColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Title',
                selector: row => row.title,
                cell: row => <span style={{ width: "100%" }} title={row?.title}>{row?.title}</span>,
                sortable: true,
            },
            {
                name: 'Freelancers',
                selector: row => row?.freelancers,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                cell: row => <span title={moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}>{moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>,
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row, "Curren")}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row, "Curren")}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row, "Curren")}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [currencies]);

    useEffect(() => {
        setLangColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Title',
                selector: row => row.title,
                cell: row => <span style={{ width: "100%" }} title={row?.title}>{row?.title}</span>,
                sortable: true,
            },
            {
                name: 'Freelancers',
                selector: row => row?.freelancers,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                cell: row => <span title={moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}>{moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>,
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row, "Lang")}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row, "Lang")}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row, "Lang")}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [languages]);

    useEffect(() => {
        setScreenColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Title',
                selector: row => row.title,
                cell: row => <span style={{ width: "100%" }} title={row?.title}>{row?.title}</span>,
                sortable: true,
            },
            {
                name: 'Route',
                selector: row => row?.route,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                cell: row => <span title={moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}>{moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>,
                sortable: true,
                maxWidth: "180px"
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row, "Screen")}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    {
                        checkPermission('plans', 'update') &&
                        <Button onClick={() => handleUpdateProj(row, "Screen")}
                            title={"Edit"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 20 }}></span></Button>
                    }
                    {
                        checkPermission('plans', 'delete') &&
                        <Button onClick={() => handleDeleteProj(row, "Screen")}
                            title={"Delete"}
                            style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 20 }}></span></Button>
                    }
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [screens]);

    const getData = async () => {
        if (!loading) {
            setLoading(true);
            await new Promise((resolve, reject) => {
                getCharge(resolve, reject);
            });
            await new Promise((resolve, reject) => {
                getCurrency(resolve, reject);
            });
            await new Promise((resolve, reject) => {
                getLang(resolve, reject);
            });
            await new Promise((resolve, reject) => {
                getScreen(resolve, reject);
            });
            setLoading(false);
        }
    }

    const getCharge = (resolve, reject) => {
        setLoading(true);
        get("charge", { token: user?.token })
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setCharges(res?.data);
                }
                if (resolve)
                    resolve(res);
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
                if (reject)
                    reject(err);
                setLoading(false);
            })
    }

    const getCurrency = (resolve, reject) => {
        setLoading(true);
        get("currency", { token: user?.token })
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setCurrencies(res?.data);
                }
                if (resolve)
                    resolve(res);
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
                if (reject)
                    reject(err);
                setLoading(false);
            })
    }

    const getLang = (resolve, reject) => {
        setLoading(true);
        get("language", { token: user?.token })
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setLanguages(res?.data);
                }
                if (resolve)
                    resolve(res);
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
                if (reject)
                    reject(err);
                setLoading(false);
            })
    }

    const getScreen = (resolve, reject) => {
        setLoading(true);
        get("screen", { token: user?.token })
            .then(res => {
                setLoading(false);
                if (res?.statusCode == 200) {
                    setScreens(res?.data);
                }
                if (resolve)
                    resolve(res);
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
                if (reject)
                    reject(err);
                setLoading(false);
            })
    }

    const handleUpdateStatus = (row, to) => {
        if (checkPermission("plans", "update")) {
            setCurrentData({ ...row, actionType: "Status", to });
            setConfirm(true);
        } else
            toast.error("You are not authorized for this action!");
    }

    const handleUpdateProj = (row, to) => {
        setCurrentData(row);
        if (to == "Charge")
            setIsAdd(true);
        else if (to == "Lang")
            setIsLangAdd(true);
        else if (to == "Curren")
            setIsCurrenAdd(true);
        else if (to == "Screen")
            setIsScreenAdd(true);
    }

    const handleDeleteProj = (row, to) => {
        setCurrentData({ ...row, actionType: "Delete", to });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                if (currentData?.to == "Charge") {
                    body = { ...body, chargeId: currentData?._id }
                    put("charge", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getCharge();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                } else if (currentData?.to == "Lang") {
                    body = { ...body, languageId: currentData?._id }
                    put("language", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getLang();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                } else if (currentData?.to == "Curren") {
                    body = { ...body, currencyId: currentData?._id }
                    put("currency", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getCurrency();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                } else if (currentData?.to == "Screen") {
                    body = { ...body, screenId: currentData?._id }
                    put("screen", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getScreen();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                }
            }
            if (currentData?.actionType == 'Delete') {
                if (currentData?.to == "Charge") {
                    body = { ...body, chargeId: currentData?._id }
                    del("charge", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getCharge()
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                } else if (currentData?.to == "Lang") {
                    body = { ...body, languageId: currentData?._id }
                    del("language", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getLang();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                } else if (currentData?.to == "Curren") {
                    body = { ...body, currencyId: currentData?._id }
                    del("currency", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getCurrency();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                } else if (currentData?.to == "Screen") {
                    body = { ...body, screenId: currentData?._id }
                    del("screen", body)
                        .then(res => {
                            setLoading(false);
                            if (res?.statusCode == 200) {
                                setConfirm(false);
                                getScreen();
                            } else
                                toast.error("" + res?.error);
                        })
                        .catch(err => {
                            setLoading(false);
                            console.error("error while updating data", err);
                            toast.error("Something Went Wrong!");
                        })
                }
            }
        }
    }

    const checkPermission = (to, type) => {
        if (user?.role == "Admin")
            return true;
        else if (user?.role == "Sub") {
            let permission = user?.permissions;
            let find = permission.find(x => Object.keys(x).includes(to))
            if (find)
                return find[to][type]
        } else {
            return false;

        }
    }

    const handleValidSubmit = (e, v) => {
        const body = {
            ...v,
            token: user?.token
        }
        if (currentData?._id) {
            body.chargeId = currentData?._id;
            put("charge", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getCharge();
                        setIsAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        } else {
            post("charge", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getCharge();
                        setIsAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        }
    }

    const handleValidCurrenSubmit = (e, v) => {
        const body = {
            ...v,
            token: user?.token
        }
        if (currentData?._id) {
            body.currencyId = currentData?._id;
            put("currency", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getCurrency();
                        setIsCurrenAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        } else {
            post("currency", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getCurrency();
                        setIsCurrenAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        }
    }

    const handleValidLangSubmit = (e, v) => {
        const body = {
            ...v,
            token: user?.token
        }
        if (currentData?._id) {
            body.languageId = currentData?._id;
            put("language", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getLang();
                        setIsLangAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        } else {
            post("language", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getLang();
                        setIsLangAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        }
    }

    const handleValidScreenSubmit = (e, v) => {
        const body = {
            ...v,
            token: user?.token
        }
        if (currentData?._id) {
            body.screenId = currentData?._id;
            put("screen", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getScreen();
                        setIsScreenAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        } else {
            post("screen", body)
                .then(res => {
                    if (res?.statusCode == 200) {
                        toast.success(res?.message);
                        getScreen();
                        setIsScreenAdd(false);
                    }
                    else
                        toast.error(res?.error);
                })
                .catch(err => {
                    console.log('error whiling setting setting', err);
                    toast.error(err + "");
                })
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Loader visible={loading} />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            <Modal className="modal_form" isOpen={isAdd} style={{ maxWidth: 800 }} centered={true}>
                <ModalBody className="py-3 px-5">
                    <h5 className="text-black font-size-20">{currentData?._id ? 'Update' : "Add More"} Charge</h5>
                    <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col lg={12} md={12}>
                                    <div className="mb-3 mt-2">
                                        <AvField
                                            name="title"
                                            label="Charge Title *"
                                            className="form-control"
                                            placeholder="Enter the title"
                                            type="text"
                                            required
                                            value={currentData?.title}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                    onClick={() => { setIsAdd(false); }}
                                                    type="reset"
                                                >
                                                    Cancel
                                                </button>
                                            </Col>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                >
                                                    {currentData?._id ? 'Update' : "Add"}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
            <Modal className="modal_form" isOpen={isCurrenAdd} style={{ maxWidth: 800 }} centered={true}>
                <ModalBody className="py-3 px-5">
                    <h5 className="text-black font-size-20">{currentData?._id ? 'Update' : "Add More"} Currency</h5>
                    <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidCurrenSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col lg={12} md={12}>
                                    <div className="mb-3 mt-2">
                                        <AvField
                                            name="title"
                                            label="Currency Title *"
                                            className="form-control"
                                            placeholder="Enter the currency title"
                                            type="text"
                                            required
                                            value={currentData?.title}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                    onClick={() => { setIsCurrenAdd(false); }}
                                                    type="reset"
                                                >
                                                    Cancel
                                                </button>
                                            </Col>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                >
                                                    {currentData?._id ? 'Update' : "Add"}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
            <Modal className="modal_form" isOpen={isLangAdd} style={{ maxWidth: 800 }} centered={true}>
                <ModalBody className="py-3 px-5">
                    <h5 className="text-black font-size-20">{currentData?._id ? 'Update' : "Add More"} Language</h5>
                    <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidLangSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col lg={12} md={12}>
                                    <div className="mb-3 mt-2">
                                        <AvField
                                            name="title"
                                            label="Language Title *"
                                            className="form-control"
                                            placeholder="Enter the language title"
                                            type="text"
                                            required
                                            value={currentData?.title}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                    onClick={() => { setIsLangAdd(false); }}
                                                    type="reset"
                                                >
                                                    Cancel
                                                </button>
                                            </Col>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                >
                                                    {currentData?._id ? 'Update' : "Add"}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
            <Modal className="modal_form" isOpen={isScreenAdd} style={{ maxWidth: 800 }} centered={true}>
                <ModalBody className="py-3 px-5">
                    <h5 className="text-black font-size-20">{currentData?._id ? 'Update' : "Add More"} Screen</h5>
                    <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidScreenSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col lg={12} md={12}>
                                    <div className="mb-3 mt-2">
                                        <AvField
                                            name="title"
                                            label="Screen Title *"
                                            className="form-control"
                                            placeholder="Enter the screen title"
                                            type="text"
                                            required
                                            value={currentData?.title}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mb-3 mt-2">
                                        <AvField
                                            name="route"
                                            label="Screen Route *"
                                            className="form-control"
                                            placeholder="Enter the screen route (Ask for App Developer)"
                                            type="text"
                                            required
                                            value={currentData?.route}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mt-4">
                                        <Row>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                    onClick={() => { setIsScreenAdd(false); }}
                                                    type="reset"
                                                >
                                                    Cancel
                                                </button>
                                            </Col>
                                            <Col md={6}>
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                    style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                                                >
                                                    {currentData?._id ? 'Update' : "Add"}
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
            <div className="main main-app p-3 p-lg-4">
                <Card className='mb-2'>
                    <Row className='mb-2'>
                        <Col md={12}>
                            <CardBody>
                                <CardTitle><b>Meta Data</b></CardTitle>
                                <CardSubtitle>Data that users need to complete their <code>profile</code>.</CardSubtitle>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
                <Card className='mb-2'>
                    <Row className='mb-2 align-items-center'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>Charges</b></CardTitle>
                                <CardSubtitle>Charges that users need to complete their <code>profile</code> as a <code>Freelancer</code>.</CardSubtitle>
                            </CardBody>
                        </Col>
                        {checkPermission("plans", "write") ?
                            <Col md={1}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={() => { setCurrentData(null); setIsAdd(true) }} className="btn-sign" style={{ float: 'right' }}>Add</Button>
                                </div>
                            </Col>
                            : null}
                    </Row>
                    <DataTable
                        columns={chargeColumns}
                        data={charges}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                },
                            }
                        }}
                    />
                </Card>
                <Card className='mb-2'>
                    <Row className='mb-2 align-items-center'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>Currency</b></CardTitle>
                                <CardSubtitle>Currency that users need to complete their <code>profile</code> as a <code>Freelancer</code>.</CardSubtitle>
                            </CardBody>
                        </Col>
                        {checkPermission("plans", "write") ?
                            <Col md={1}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={() => { setCurrentData(null); setIsCurrenAdd(true) }} className="btn-sign" style={{ float: 'right' }}>Add</Button>
                                </div>
                            </Col>
                            : null}
                    </Row>
                    <DataTable
                        columns={currenColumns}
                        data={currencies}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                },
                            }
                        }}
                    />
                </Card>
                <Card>
                    <Row className='mb-2 align-items-center'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>Languages</b></CardTitle>
                                <CardSubtitle>Languages that users need to complete their <code>profile</code> as a <code>Freelancer</code>.</CardSubtitle>
                            </CardBody>
                        </Col>
                        {checkPermission("plans", "write") ?
                            <Col md={1}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={() => { setCurrentData(null); setIsLangAdd(true) }} className="btn-sign" style={{ float: 'right' }}>Add</Button>
                                </div>
                            </Col>
                            : null}
                    </Row>
                    <DataTable
                        columns={langColumns}
                        data={languages}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                },
                            }
                        }}
                    />
                </Card>
                <Card>
                    <Row className='mb-2 align-items-center'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>App Screens</b></CardTitle>
                                <CardSubtitle>App Screens that exists in our <code>App</code> as a <code>Routes</code>.</CardSubtitle>
                            </CardBody>
                        </Col>
                        {checkPermission("plans", "write") ?
                            <Col md={1}>
                                <div className='w-100' style={{ paddingTop: 15, paddingRight: 15 }}>
                                    <Button type="button" onClick={() => { setCurrentData(null); setIsScreenAdd(true) }} className="btn-sign" style={{ float: 'right' }}>Add</Button>
                                </div>
                            </Col>
                            : null}
                    </Row>
                    <DataTable
                        columns={screenCoulmns}
                        data={screens}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                },
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
        </React.Fragment>
    )
}