import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Modal,
  ModalBody,
  Label
} from "reactstrap";
import './style.scss';

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux";
import * as Utils from '../../Utils';
import Loader from "../../components/Loader";
import { toast } from 'react-toastify';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { get, put } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import Header from '../../layouts/Header';

export default function Setting() {
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [isMaintain, setIsMaintain] = useState(false);
  const [autoPost, setAutoPost] = useState(false);
  const [currentData, setCurrentData] = useState(undefined);
  const [postConfigModal, setPostConfigModal] = useState(false);

  useEffect(() => {
    if (user)
      getSettings();
  }, [user]);

  const getSettings = () => {
    get('setting', { token: user?.token })
      .then(res => {
        if (res?.statusCode == 200) {
          setIsMaintain(res?.data?.maintinance);
          setAutoPost(res?.data?.autoPost);
        } else {
          toast.error(res?.error);
        }
      })
      .catch(err => {
        console.log('error while getting setting', err);
        toast.error("" + err);
      });
  }

  const handleChangeToggle = (val, to) => {
    console.log('changing toggle', val, typeof val)
    if (user?.role != "Admin")
      if (user?.permissions?.length)
        if (!user?.permissions[19].settings?.update) {
          toast.error("You're not authorized for this action!");
          return;
        }
    const body = {
      [to]: !val,
      token: user?.token
    }
    put("setting", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          setIsMaintain(res?.data?.maintinance);
          setAutoPost(res?.data?.autoPost);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  // console.log('getting setting', isStory);

  const handleValidSubmit = (e, v) => {
    const body = {
      ...v,
      token: user?.token
    }
    put("setting", body)
      .then(res => {
        if (res?.statusCode == 200) {
          toast.success(res?.message);
          getSettings();
          setPostConfigModal(false);
        }
        else
          toast.error(res?.error);
      })
      .catch(err => {
        console.log('error whiling setting setting', err);
        toast.error(err + "");
      })
  }

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Modal className="modal_form" isOpen={postConfigModal} style={{ maxWidth: 800 }} centered={true}>
          <ModalBody className="py-3 px-5">
            <h5 className="text-black font-size-20">{'Configure Auto Post'}</h5>
            <div className="p-2">
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <Col lg={12} md={12}>
                    <div className="mb-3 mt-2">
                      <AvField
                        name="postPerDay"
                        label="Post per Day *"
                        className="form-control"
                        placeholder="Enter the number of post, how many per day will be added."
                        type="number"
                        required
                        value={currentData?.postPerDay}
                      // validate={{min:{value:1}}}
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={12}>
                    <div className="mt-4">
                      <Row>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                            onClick={() => { setPostConfigModal(false); }}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col md={6}>
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            style={{ backgroundColor: Utils.themeColor, border: 'none' }}
                          >
                            Update
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
        <Row>
          {/* maintinance */}
          <Col md={4} className='mb-4'>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='ri-tools-line' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Maintinance</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="maintinance"
                      onChange={(e) => handleChangeToggle(isMaintain, 'maintinance')}
                      checked={isMaintain}
                    />
                    <label className="toggle-switch-label" htmlFor="maintinance">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* wellness */}
          <Col md={4} className='mb-4'>
            <Card>
              <CardBody>
                <div className='d-flex align-items-center justify-content-between'>
                  <i className='ri-magic-fill' style={{ color: Utils.themeColor, fontSize: 50, marginRight: 20 }} />
                  <span style={{ fontWeight: 'bolder' }}>Auto post</span>
                  <div className="toggle-switch card-button-box">
                    <input
                      type="checkbox"
                      className="toggle-switch-checkbox"
                      name="toggleSwitch"
                      id="autoPost"
                      onChange={(e) => handleChangeToggle(autoPost, 'autoPost')}
                      checked={autoPost}
                    />
                    <label className="toggle-switch-label" htmlFor="autoPost">
                      <span className="toggle-switch-inner" />
                      <span className="toggle-switch-switch" />
                    </label>
                    <Button onClick={() => { setCurrentData(); setPostConfigModal(true) }}
                      title={"Edit"}
                      style={{ backgroundColor: Utils.themeColor, color: "#fff", border: 'none' }}><span className="ri-edit-line" style={{ fontSize: 20 }}></span>
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}