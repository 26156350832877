export const projectName = "Tasky Fox";
export const themeColor = "#c35817";
export const themeColorDark = "#55a657";
// export const API_URL = "http://192.168.1.148:8087/";
export const API_URL = "https://api.taskyfox.com/";

export const dummySeo = `<title>Onward Workspace</title>
<meta name="description" content="">
<meta name="keywords" content="">
<meta property="og:type" content="website">
<meta property="og:title" content="">
<meta property="og:description" content="">
<meta property="og:url" content="">
<meta property="og:image" content="">
<meta name="robots" content="index, follow">
<link rel="canonical" href="">
<meta name="viewport" content="width=device-width, initial-scale=1.0">`;