import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalBody, Label } from 'reactstrap';
import { del, get, post, put, upload, uploadS3 } from '../../helper/api_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../../Utils";
import ProgressBar from "../../components/ProgressBar";


function AddForm(props) {
    const { setIsAdd, user, currentData, loading, setLoading, getData, currentScreen } = props;
    const [cropperModal, setCropperModal] = useState(false);
    const [file, setFile] = useState(null);
    const [adsType, setAdsType] = useState("");
    const [adsSize, setAdsSize] = useState("");
    const [image, setImage] = useState(null);
    const imageInput = useRef();
    const cropper = useRef();
    const [progress, setProgress] = useState(0);

    useState(() => {
        if (currentData) {
            setFile(currentData?.source);
            setImage(currentData?.source);
            setAdsType(currentData?.title);
            setAdsSize(currentData?.type);
        }
    }, [currentData])

    const handleImgChange = (file) => {
        if (file) {
            setFile(URL.createObjectURL(file));
            if (adsType == "Image")
                setCropperModal(true);
        }
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            let body = {
                ...v,
                currentScreen,
                token: user?.token
            }
            if (file && file.substring(0, 4) == "blob") {
                if (adsType == "Image") {
                    const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
                    let obj = await urltoFile(fileData, new Date().getTime() + '.webp', 'image/webp');
                    obj = await resizeFile(obj);
                    const imageRes = await uploadS3("ads", obj, adsType == "Image" ? "webp" : "mp4", (p) => setProgress(p));
                    body.source = imageRes?.data;
                } else {
                    let obj = await urltoFile(file, new Date().getTime() + ".mp4", "video/mp4");
                    const imageRes = await uploadS3("ads", obj, adsType == "Image" ? "webp" : "mp4", (p) => setProgress(p));
                    body.source = imageRes?.data;
                }
                setProgress(0);
            }
            setLoading(true);
            if (currentData?._id) {
                body = { ...body, adsId: currentData?._id }
                put("ads", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("ads", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        if (url)
            return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    // console.log("updateing plan", currentData);

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <input
                    name="image"
                    label="image"
                    className="d-none"
                    type="file"
                    accept={adsType == "Image" ? "image/*" : "video/*"}
                    onChange={(e) => handleImgChange(e.target.files[0])}
                    ref={imageInput}
                />
                <ProgressBar
                    progress={progress}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={10}>
                                <CardTitle className='mb-4'><b>{currentData?._id ? 'Update category' : 'Add New category'}</b></CardTitle>
                            </Col>
                            <Col>
                                <Button type="button" onClick={() => setIsAdd(false)} className="btn-sign" style={{ float: 'right' }}>Close</Button>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="title"
                                            label="Type *"
                                            type="select"
                                            required
                                            value={adsType}
                                            onChange={(e) => setAdsType(e?.target?.value)}
                                        >
                                            <option value="">Select Ads Type</option>
                                            <option value="Image">Image</option>
                                            <option value="Video">Video</option>
                                            <option value="IFrame">IFrame</option>
                                        </AvField>
                                    </div>
                                </Col>
                                {adsType == "Image" || adsType == "Video" ?
                                    <Col md={6}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="type"
                                                label="Size *"
                                                type="select"
                                                required
                                                value={adsSize}
                                                onChange={(e) => setAdsSize(e?.target?.value)}
                                            >
                                                <option value="">Select Ads Size</option>
                                                <option value="Banner">Banner</option>
                                                <option value="Full">Full Screen</option>
                                            </AvField>
                                        </div>
                                    </Col>
                                    : null}
                                {adsType == "Image" && adsSize == "Banner" ?
                                    <Col md={12} className='mt-4 mb-4'>
                                        {file ?
                                            <>
                                                {image ?
                                                    <div style={{ width: 500, position: 'relative' }}>
                                                        <img
                                                            src={image}
                                                            alt=""
                                                            style={{ width: 500, height: 300 }}
                                                        // onClick={(e) => imageInput.current.click(e)}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -28, top: -28, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={() => { setFile(null); setImage("") }}
                                                        >
                                                            <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <Cropper
                                                        style={{ height: 300, width: 500 }}
                                                        aspectRatio={1920 / 1080}
                                                        preview=".img-preview"
                                                        guides={true}
                                                        src={file}
                                                        ref={cropper}
                                                    />
                                                }
                                            </>
                                            :
                                            <div style={{
                                                position: 'relative',
                                                width: 500,
                                                height: 300,
                                                border: '1px dashed ' + Utils.themeColor,
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: Utils.themeColor,
                                                fontSize: 22
                                            }} onClick={(e) => imageInput.current.click(e)}>
                                                <span
                                                    style={{
                                                        padding: 5,
                                                        paddingLeft: 15, paddingRight: 15,
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    <i className='ri-image-line' />
                                                </span>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                >
                                                    <i className="ri-camera-line" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    : null}
                                {adsType == "Image" && adsSize == "Full" ?
                                    <Col md={12} className='mt-4 mb-4'>
                                        {file ?
                                            <>
                                                {image ?
                                                    <div style={{ width: 250, position: 'relative' }}>
                                                        <img
                                                            src={image}
                                                            alt=""
                                                            style={{ width: 250, height: 400 }}
                                                        // onClick={(e) => imageInput.current.click(e)}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -28, top: -28, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={() => { setFile(null); setImage("") }}
                                                        >
                                                            <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <Cropper
                                                        style={{ height: 400, width: 250 }}
                                                        aspectRatio={1080 / 1920}
                                                        preview=".img-preview"
                                                        guides={true}
                                                        src={file}
                                                        ref={cropper}
                                                    />
                                                }
                                            </>
                                            :
                                            <div style={{
                                                position: 'relative',
                                                width: 250,
                                                height: 400,
                                                border: '1px dashed ' + Utils.themeColor,
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: Utils.themeColor,
                                                fontSize: 22
                                            }} onClick={(e) => imageInput.current.click(e)}>
                                                <span
                                                    style={{
                                                        padding: 5,
                                                        paddingLeft: 15, paddingRight: 15,
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    <i className='ri-image-line' />
                                                </span>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                >
                                                    <i className="ri-camera-line" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    : null}
                                {adsType == "Video" && adsSize == "Banner" ?
                                    <Col md={12} className='mt-4 mb-4'>
                                        {file ?
                                            <div style={{ width: 500, position: 'relative' }}>
                                                <video
                                                    src={file}
                                                    alt=""
                                                    style={{ width: 500, height: 300 }}
                                                    controls
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -28, top: -28, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                    onClick={() => { setFile(null); setImage("") }}
                                                >
                                                    <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                            :
                                            <div style={{
                                                position: 'relative',
                                                width: 500,
                                                height: 300,
                                                border: '1px dashed ' + Utils.themeColor,
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: Utils.themeColor,
                                                fontSize: 22
                                            }} onClick={(e) => imageInput.current.click(e)}>
                                                <span
                                                    style={{
                                                        padding: 5,
                                                        paddingLeft: 15, paddingRight: 15,
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    <i className='ri-video-line' />
                                                </span>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                >
                                                    <i className="ri-image-line" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    : null}
                                {adsType == "Video" && adsSize == "Full" ?
                                    <Col md={12} className='mt-4 mb-4'>
                                        {file ?
                                            <div style={{ width: 250, position: 'relative' }}>
                                                <video
                                                    src={file}
                                                    alt=""
                                                    style={{ width: 250, height: 400 }}
                                                    controls
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -28, top: -28, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                    onClick={() => { setFile(null); setImage("") }}
                                                >
                                                    <i className="ri-close-line" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                            :
                                            <div style={{
                                                position: 'relative',
                                                width: 250,
                                                height: 400,
                                                border: '1px dashed ' + Utils.themeColor,
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: Utils.themeColor,
                                                fontSize: 22
                                            }} onClick={(e) => imageInput.current.click(e)}>
                                                <span
                                                    style={{
                                                        padding: 5,
                                                        paddingLeft: 15, paddingRight: 15,
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    <i className='ri-video-line' />
                                                </span>
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                >
                                                    <i className="ri-image-line" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    : null}
                                {adsType == "Image" || adsType == "Video" ?
                                    <Col md={6}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="targetUrl"
                                                label="Target Url *"
                                                placeholder="Enter the target url"
                                                value={currentData?.targetUrl}
                                                type="url"
                                                required
                                            />
                                        </div>
                                    </Col>
                                    : null}
                                {adsType == "IFrame" ?
                                    <Col md={12}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="source"
                                                label="Source *"
                                                placeholder="Enter the iframe code"
                                                value={currentData?.source}
                                                type="textarea"
                                                rows={5}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    : null}
                            </Row>
                            <Row>
                                <Col md={4} />
                                <Col md={2}>
                                    <Button type="reset" onClick={() => setIsAdd(false)} className="btn-sign w-100">Cancel</Button>
                                </Col>
                                <Col md={2}>
                                    <Button type="submit" className="btn-sign w-100">{currentData?._id ? 'Update' : 'Add'}</Button>
                                </Col>
                                <Col md={4} />
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card >
            </div >
        </React.Fragment >
    )
}

export default AddForm;