import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { useSelector } from 'react-redux';
import { get } from "../../helper/api_helper";
import moment from "moment";
import DataTable from 'react-data-table-component';

export default function Dashboard() {

    const user = useSelector(state => state.user);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("stat", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setPosts(res?.data?.posts);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while getting stat", err);
                })
        }
    }

    useEffect(() => {
        setColumns([
            {
                name: 'S.N',
                selector: (row, index) => index + 1,
                sortable: true,
                maxWidth: "100px"
            },
            {
                name: 'User',
                selector: row => row.user,
                cell: row => <span style={{ width: "100%" }} title={row?.user}>{row?.user} {row?.userId ? "" : <i className="ri-robot-line" />}</span>,
                sortable: true,
                maxWidth: "180px"
            },
            {
                name: 'Requirement',
                selector: row => row.desc,
                cell: row => <span style={{ width: "100%" }}><p style={{ width: "100%", marginBottom: 0 }}>{row?.desc}</p></span>,
                sortable: true
            },
            {
                name: 'Category',
                selector: row => row?.category,
                cell: row => <span title={row?.category}>{row?.category}</span>,
                sortable: true,
                maxWidth: "150px"
            },
            {
                name: 'Sub Cats',
                selector: row => row.subCats,
                cell: row => <span style={{ width: "100%" }}>{row.subCats?.map((item) => <p style={{ width: "100%", marginBottom: 0 }}>{item?.title}, </p>)}</span>,
                sortable: true,
                maxWidth: "180px"
            },
            {
                name: 'Created At',
                selector: row => moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a"),
                cell: row => <span title={moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}>{moment.tz(row?.createdAt, "Asia/Kolkata").format("DD MMM, YYYY hh:mm a")}</span>,
                sortable: true,
                maxWidth: "180px"
            }
        ])
    }, [posts]);

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"></li>
                        </ol>
                        <h4 className="main-title mb-0">Welcome to Dashboard</h4>
                    </div>
                </div>
                <div style={{ height: "60vh" }}>
                    <DataTable
                        columns={columns}
                        data={posts}
                        // pagination
                        // paginationPerPage={50}
                        // paginationRowsPerPageOptions={[50, 100, 200, 300, 400, 500]}
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                },
                            }
                        }}
                    />
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}
