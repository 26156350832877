import React from "react";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import Enquiry from "../pages/Users/Enquiry";
import Links from "../pages/FooterLinks";
import SocialLinks from "../pages/Social";
import Banner from "../pages/Banner";
import SubAdmin from "../pages/SubAdmin";
import Category from "../pages/Category";
import SubCategory from "../pages/SubCategory";
import MetaData from "../pages/Meta";

import FakeUser from "../pages/FakeUser";
import FakeCity from "../pages/FakeCity";
import Requirement from "../pages/Requirements";

import Setting from "../pages/Setting";
import Posts from "../pages/Posts";
import Ads from "../pages/Ads";

const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/users", element: <Users /> },
  { path: "/enquiry", element: <Enquiry /> },
  { path: "/links", element: <Links /> },
  { path: "/social", element: <SocialLinks /> },
  { path: "/posts", element: <Posts /> },
  { path: "/ads", element: <Ads /> },
  { path: "/banner", element: <Banner /> },
  { path: "/sub-admin", element: <SubAdmin /> },
  { path: "/categories", element: <Category /> },
  { path: "/sub-categories", element: <SubCategory /> },
  { path: "/meta-data", element: <MetaData /> },
  { path: "/fake-users", element: <FakeUser /> },
  { path: "/fake-city", element: <FakeCity /> },
  { path: "/requirement", element: <Requirement /> },
  { path: "/setting", element: <Setting /> },
]

export default protectedRoutes;